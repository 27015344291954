import React, { useState, useEffect } from 'react';
import taxData from './taxData.json';  // Import the JSON data

function App() {
  const [currentYear, setCurrentYear] = useState('2024/25');
  const [previousYear, setPreviousYear] = useState('2023/24');
  const [currentTable, setCurrentTable] = useState('income_tax');
  const [data, setData] = useState([]);
  const [dataPrevious, setDataPrevious] = useState([]);
  const [personalAllowances, setPersonalAllowances] = useState([]);
  const [personalAllowancesPrevious, setPersonalAllowancesPrevious] = useState([]);
  const [footnote, setFootnote] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    try {
      // Ensure the selected table and years exist in the JSON
      if (taxData[currentYear] && taxData[previousYear]) {
        const currentTableData = taxData[currentYear][currentTable];
        const previousTableData = taxData[previousYear][currentTable];
        
        if (currentTableData) {
          setTitle(currentTableData.title || '');
          setData(currentTableData.data || []);
          setDataPrevious(previousTableData?.data || []);

          // Check if personal allowances exist for the selected table
          if (currentTableData.personal_allowances) {
            setPersonalAllowances(currentTableData.personal_allowances.data || []);
            setPersonalAllowancesPrevious(previousTableData?.personal_allowances?.data || []);
            setFootnote(currentTableData.personal_allowances.footnotes || []);
          } else {
            setPersonalAllowances([]);
            setPersonalAllowancesPrevious([]);
            setFootnote('');
          }
        } else {
          setTitle('');
          setData([]);
          setDataPrevious([]);
          setPersonalAllowances([]);
          setPersonalAllowancesPrevious([]);
          setFootnote('');
        }
      }
    } catch (error) {
      console.error('Error loading tax data:', error);
      setTitle('');
      setData([]);
      setDataPrevious([]);
      setPersonalAllowances([]);
      setPersonalAllowancesPrevious([]);
      setFootnote('');
    }
  }, [currentYear, currentTable, previousYear]);

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setCurrentYear(selectedYear);
    setPreviousYear(selectedYear === '2024/25' ? '2023/24' : '2022/23');
  };

  const showTaxTable = (tableKey) => {
    setCurrentTable(tableKey);
  };

  return (
    <div className="container">
      <div className="sidebar">
        <h2>Tax Rates</h2>
        <div className="year-select">
          <label htmlFor="year">Select Tax Year: </label>
          <select id="year" value={currentYear} onChange={handleYearChange}>
            <option value="2024/25">2024/25</option>
            <option value="2023/24">2023/24</option>
          </select>
        </div>
        <ul>
          <li
            className={currentTable === 'income_tax' ? 'active' : ''}
            onClick={() => showTaxTable('income_tax')}
          >
            Income Tax
          </li>
          <li
            className={currentTable === 'national_insurance' ? 'active' : ''}
            onClick={() => showTaxTable('national_insurance')}
          >
            National Insurance
          </li>
          <li
            className={currentTable === 'capital_gains_tax' ? 'active' : ''}
            onClick={() => showTaxTable('capital_gains_tax')}
          >
            Capital Gains Tax
          </li>
          <li
            className={currentTable === 'corporation_tax' ? 'active' : ''}
            onClick={() => showTaxTable('corporation_tax')}
          >
            Corporation Tax
          </li>
          <li
            className={currentTable === 'vat' ? 'active' : ''}
            onClick={() => showTaxTable('vat')}
          >
            VAT
          </li>
          <li
            className={currentTable === 'inheritance_tax' ? 'active' : ''}
            onClick={() => showTaxTable('inheritance_tax')}
          >
            Inheritance Tax
          </li>
        </ul>
      </div>
      <div className="content">
        {title && <h1>{title}</h1>}
        <table>
          <thead>
            <tr>
              <th>Threshold</th>
              <th>{currentYear} Rate</th>
              <th>{previousYear} Rate</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{row.threshold}</td>
                <td>{row.rate}</td>
                <td>{dataPrevious[index]?.rate || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Personal Allowances Section */}
        {personalAllowances.length > 0 && (
  <>
    <h2>{taxData[currentYear][currentTable].personal_allowances?.title}</h2>
    <table>
      <thead>
        <tr>
          <th>Allowance</th>
          <th>{currentYear}</th>
          <th>{previousYear}</th>
        </tr>
      </thead>
      <tbody>
        {personalAllowances.map((row, index) => (
          <tr key={index}>
            <td>{row.threshold}</td>
            <td>{row.rate}</td>
            <td>{personalAllowancesPrevious[index]?.rate || 'N/A'}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="footnote">
      {footnote.map((note, index) => (
        <p key={index}>{note}</p>
      ))}
    </div>
  </>
)}
      </div>
    </div>
  );
}

export default App;